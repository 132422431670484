import { FC, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, Stack, Grid, Button, Alert, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { UserContext } from '../../../context';
import {
  Modal,
  Loader,
  TextField,
  SelectAsync,
  ConfirmPrompt,
  SaveButton,
  ConfirmCheckboxModal,
  CreditCardInputs,
  CancelIcon,
  AmountInput,
} from '../../../components';
import {
  createAccountTransaction,
  getAccountAddress,
  getAllTranCodes,
  payInvoice,
  payInvoiceDeposit,
} from '../../../fetch';
import {
  ISelectOption,
  ITranCode,
  IAccountSimple,
  IAccountAddress,
  IResponse,
  PaymentProcessor,
} from '../../../models';
import {
  convertToNumber,
  formatMoney,
  formatRawDate,
  formatShortFriendlyDateWithTime,
} from '../../../helpers';
import { useConfirm } from '../../../hooks';
import { defaultUnsavedChangesMessage } from '../../../constants';
import { useQuery } from 'react-query';
import { AdyenDropInWrapper } from '../../../components/adyen/adyen-drop-in-wrapper';

interface ITransactionModalProps {
  accountId: string;
  isOpen: boolean;
  onClose: (shouldUpdate?: boolean) => void;
  isOtsInvoice?: boolean;
  defaultAmount?: string | number;
  repairId?: string;
  paymentMethodId?: string | null;
  currentCustomer?: IAccountSimple | null;
  reference?: string;
  isForSecurityDeposit?: boolean;
  isForAdditionalSecurityDeposit?: boolean;
  transactionId?: string;
}

const FORM_VALIDATION = Yup.object().shape({
  tranCodeId: Yup.string().required('Required'),
  businessDate: Yup.string().required('Required'),
  reference: Yup.string().required('Required'),
  paymentMethod: Yup.string(),
  amountEach: Yup.mixed()
    .when(['isOtsInvoice', 'paymentMethod'], {
      is: (isOtsInvoice: boolean, paymentMethod: string) =>
        !isOtsInvoice && paymentMethod === 'Adjustment',
      then: Yup.string().required('Required'),
      otherwise: Yup.string()
        .test('min', 'Must be positive and greater than 0', (val, ctx) => {
          return val && convertToNumber(val) > 0 ? true : false;
        })
        .required('Required'),
    })
    .when('isOtsInvoice', {
      is: (val: boolean, isOtsInvoice: boolean) => val && isOtsInvoice,
      then: Yup.string()
        .test('min', 'Must be positive and greater than 0', (val, ctx) =>
          val && convertToNumber(val) > 0 ? true : false
        )
        .test('max', 'Amount can`t be greater than balance', (val, ctx) =>
          val && convertToNumber(val) > convertToNumber(ctx?.parent?.defaultAmount) ? false : true
        )
        .required('Required'),
    }),

  whenPosted: Yup.string().required('Required'),
  postedBy: Yup.string().required('Required'),
  isOtsInvoice: Yup.boolean(),
  defaultAmount: Yup.number(),
  // CC Inputs
  firstName: Yup.mixed().when(['isOtsInvoice', 'paymentMethod', 'adyen'], {
    is: (isOtsInvoice: boolean, paymentMethod: string, adyen?: boolean) =>
      isOtsInvoice && paymentMethod === CREDIT_CARD_PAYMENT_METHOD && !adyen,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  lastName: Yup.mixed().when(['isOtsInvoice', 'paymentMethod', 'adyen'], {
    is: (isOtsInvoice: boolean, paymentMethod: string, adyen?: boolean) =>
      isOtsInvoice && paymentMethod === CREDIT_CARD_PAYMENT_METHOD && !adyen,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  street: Yup.mixed().when(['isOtsInvoice', 'paymentMethod', 'adyen'], {
    is: (isOtsInvoice: boolean, paymentMethod: string, adyen?: boolean) =>
      isOtsInvoice && paymentMethod === CREDIT_CARD_PAYMENT_METHOD && !adyen,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  city: Yup.mixed().when(['isOtsInvoice', 'paymentMethod', 'adyen'], {
    is: (isOtsInvoice: boolean, paymentMethod: string, adyen?: boolean) =>
      isOtsInvoice && paymentMethod === CREDIT_CARD_PAYMENT_METHOD && !adyen,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  state: Yup.mixed().when(['isOtsInvoice', 'paymentMethod', 'adyen'], {
    is: (isOtsInvoice: boolean, paymentMethod: string, adyen?: boolean) =>
      isOtsInvoice && paymentMethod === CREDIT_CARD_PAYMENT_METHOD && !adyen,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  zipCode: Yup.mixed().when(['isOtsInvoice', 'paymentMethod', 'adyen'], {
    is: (isOtsInvoice: boolean, paymentMethod: string, adyen?: boolean) =>
      isOtsInvoice && paymentMethod === CREDIT_CARD_PAYMENT_METHOD && !adyen,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  cardNumber: Yup.mixed().when(['isOtsInvoice', 'paymentMethod', 'adyen'], {
    is: (isOtsInvoice: boolean, paymentMethod: string, adyen?: boolean) =>
      isOtsInvoice && paymentMethod === CREDIT_CARD_PAYMENT_METHOD && !adyen,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  expirationMonth: Yup.mixed().when(['isOtsInvoice', 'paymentMethod', 'adyen'], {
    is: (isOtsInvoice: boolean, paymentMethod: string, adyen?: boolean) =>
      isOtsInvoice && paymentMethod === CREDIT_CARD_PAYMENT_METHOD && !adyen,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  expirationYear: Yup.mixed().when(['isOtsInvoice', 'paymentMethod', 'adyen'], {
    is: (isOtsInvoice: boolean, paymentMethod: string, adyen?: boolean) =>
      isOtsInvoice && paymentMethod === CREDIT_CARD_PAYMENT_METHOD && !adyen,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  cvv: Yup.mixed().when(['isOtsInvoice', 'paymentMethod', 'adyen'], {
    is: (isOtsInvoice: boolean, paymentMethod: string, adyen?: boolean) =>
      isOtsInvoice && paymentMethod === CREDIT_CARD_PAYMENT_METHOD && !adyen,
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  notes: Yup.string().nullable(),
  showCCWarning: Yup.boolean(),
  isCCWarningModalOpen: Yup.boolean(),
  adyen: Yup.boolean(),
});

const CREDIT_CARD_PAYMENT_METHOD = 'Credit Card Payment';

export const TransactionModal: FC<ITransactionModalProps> = ({
  accountId,
  isOpen,
  onClose,
  isOtsInvoice,
  defaultAmount,
  repairId,
  paymentMethodId,
  currentCustomer,
  reference,
  isForSecurityDeposit,
  isForAdditionalSecurityDeposit,
  transactionId,
}) => {
  const { user, paymentProcessor, hasQBInvoiceEntryType } = useContext(UserContext);
  const isAdyen = paymentProcessor?.paymentProcessor === PaymentProcessor.Adyen;
  const { enqueueSnackbar } = useSnackbar();
  const [transCodeOptions, setTransCodeOptions] = useState<ISelectOption[]>([]);

  const confirm = useConfirm();

  const handlePostTransaction = async (
    values: any,
    callback?: () => void,
    guid?: string,
    actions?: any
  ) => {
    try {
      if (isOtsInvoice) {
        const payload = {
          recurringPaymentId:
            paymentMethodId && paymentMethodId === values.tranCodeId ? paymentMethodId : null,
          tranCodeId: paymentMethodId !== values.tranCodeId ? values.tranCodeId : null,
          reference: values.reference,
          amount: convertToNumber(values.amountEach),
          businessDate: formatRawDate(values.businessDate) as string,
          creditCard:
            values.paymentMethod === CREDIT_CARD_PAYMENT_METHOD && !isAdyen
              ? {
                  creditCardNumber: values.cardNumber,
                  expirationMonth: values.expirationMonth,
                  expirationYear: values.expirationYear,
                  cvv: values.cvv,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  address: values.street,
                  postalCode: values.zipCode,
                }
              : null,
          adyenReference: guid ?? null,
        };
        isForSecurityDeposit
          ? await payInvoiceDeposit(repairId!, payload)
          : await payInvoice(repairId!, payload);

        const message = () => {
          if (reference && reference === 'Deposit received' && isForSecurityDeposit) {
            return 'Deposit received!';
          }
          return 'Invoice paid!';
        };
        enqueueSnackbar(message(), {
          variant: 'success',
        });
      } else {
        await createAccountTransaction(accountId, {
          tranCodeId: values.tranCodeId,
          reference: values.reference,
          amountEach: convertToNumber(values.amountEach),
          businessDate: formatRawDate(values.businessDate) as string,
          quantity: 1,
        });
        enqueueSnackbar(`Transaction posted!`, {
          variant: 'success',
        });
      }
      onClose(true);
      callback && callback();
    } catch (error: any) {
      actions.setSubmitting(false);
      enqueueSnackbar(
        error?.Detail ??
          `Error posting ${isOtsInvoice ? 'invoice' : 'transaction'}, please try again.`,
        {
          variant: 'error',
        }
      );
    }
  };

  const { isLoading: isLoadingAddress, data: accountAddress } = useQuery<IAccountAddress>(
    ['getAccountAddress', accountId],
    () => getAccountAddress(accountId!),
    {
      enabled: !!accountId,
    }
  );

  return (
    <Formik
      initialValues={{
        tranCodeId: '',
        reference: reference ?? '',
        businessDate: new Date(),
        amountEach: defaultAmount ? formatMoney(defaultAmount) : '',
        quantity: '1',
        whenPosted: formatShortFriendlyDateWithTime(new Date()),
        postedBy: user?.userName,
        isOtsInvoice,
        defaultAmount: defaultAmount ?? 0,
        // CC Inputs
        firstName: currentCustomer?.accountFirstName ?? '',
        lastName: currentCustomer?.accountLastName ?? '',
        street: accountAddress?.street ?? '',
        city: accountAddress?.city ?? '',
        state: accountAddress?.state ?? '',
        zipCode: accountAddress?.postalCode ?? '',
        cardNumber: '',
        expirationMonth: '',
        expirationYear: '',
        cvv: '',
        notes: '',
        showCCWarning: false,
        isCCWarningModalOpen: false,
        paymentMethod: '',
        adyen: isAdyen,
      }}
      enableReinitialize={true}
      validationSchema={FORM_VALIDATION}
      onSubmit={(values, actions) => {
        try {
          if (values.showCCWarning) {
            actions.setFieldValue('isCCWarningModalOpen', true); // Show cc warning confirmation
          } else {
            handlePostTransaction(values, actions.resetForm, undefined, actions);
          }
        } catch (error: any) {
          enqueueSnackbar(error?.Detail ?? `Error posting transaction, please try again.`, {
            variant: 'error',
          });
        }
      }}
    >
      {({
        isSubmitting,
        isValid,
        dirty,
        values,
        setFieldValue,
        resetForm,
        handleBlur,
        touched,
        errors,
        setSubmitting,
      }) => {
        const handleClose = async (resetForm: any) => {
          if (dirty) {
            const result = await confirm(defaultUnsavedChangesMessage);
            if (result) {
              resetForm();
              onClose();
            }
            return;
          }
          resetForm();
          onClose();
        };
        return (
          <>
            <Modal
              open={isOpen}
              onClose={() => {
                handleClose(resetForm);
              }}
              title={isOtsInvoice ? 'Pay Invoice' : 'Add Transaction'}
              maxWidth="md"
            >
              <Box mt={2}>
                <Form>
                  {(isSubmitting || isLoadingAddress) && (
                    <Loader position="centered" title="Loading" type="overlay" />
                  )}
                  <ConfirmPrompt
                    when={dirty && !isSubmitting}
                    message={defaultUnsavedChangesMessage}
                    onConfirm={() => {
                      onClose();
                      resetForm();
                    }}
                  />
                  <Grid container spacing={2}>
                    {!isOtsInvoice && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="whenPosted"
                            label="When Posted"
                            inputProps={{ readOnly: true }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="postedBy"
                            label="Posted By"
                            inputProps={{ readOnly: true }}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} sm={6}>
                      <SelectAsync
                        disabled={isSubmitting}
                        name="tranCodeId"
                        value={values.tranCodeId}
                        label={isOtsInvoice ? 'Payment Method' : 'Transaction Type'}
                        required
                        apiRequest={() =>
                          getAllTranCodes({
                            officeId: user?.officeId,
                            tranCodeType: 'PostAccountTransaction',
                          })
                        }
                        handleOptions={options => setTransCodeOptions(options)}
                        transformResponse={(response: IResponse<ITranCode[]>) => {
                          // for qb invoices only show adjustment transaction code
                          if (hasQBInvoiceEntryType) {
                            return response.records
                              .filter(item => item.code === 'ADJ')
                              .map(item => ({
                                label: item.description,
                                value: item.tranCodeId,
                              }));
                          }
                          // for an ots invoice only show the 4 options for credit card on file, cash, check, and credit card payment
                          if (isOtsInvoice) {
                            return [
                              {
                                label: 'Credit Card On File',
                                value: paymentMethodId ?? '',
                                disabled: !paymentMethodId ? true : false,
                              },

                              ...response.records
                                .filter(
                                  item =>
                                    item.description === 'Cash' ||
                                    item.description === 'Check' ||
                                    item.description === 'Credit Card Payment'
                                )
                                .map(item => ({
                                  label: item.description,
                                  value: item.tranCodeId,
                                })),
                            ];
                          } else {
                            return response.records.map((item: ITranCode) => ({
                              label: item.description,
                              value: item.tranCodeId,
                            }));
                          }
                        }}
                        onChange={e => {
                          const selectedOption: ISelectOption | undefined = transCodeOptions.find(
                            o => o.value === e.target.value
                          ) ?? { label: '', value: '' };

                          setFieldValue('tranCodeId', e.target.value);
                          if (reference) {
                            setFieldValue('reference', `${reference} - ${selectedOption.label}`);
                          } else {
                            setFieldValue('reference', selectedOption.label);
                          }
                          setFieldValue('paymentMethod', selectedOption.label);
                          if (
                            (selectedOption.label.includes('Credit Card Payment') ||
                              selectedOption.label.includes('Credit')) &&
                            selectedOption.label !== 'Credit Card On File' &&
                            !values.isOtsInvoice
                          ) {
                            setFieldValue('showCCWarning', true);
                          } else {
                            setFieldValue('showCCWarning', false);
                          }
                          if (
                            isOtsInvoice &&
                            (selectedOption.label.includes('Credit Card Payment') ||
                              selectedOption.label.includes('Credit Card On File'))
                          ) {
                            setFieldValue('businessDate', new Date());
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField name="reference" label="Reference" required />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DatePicker
                        label="Business Date"
                        format="MM/dd/yyyy"
                        onChange={date => {
                          setFieldValue('businessDate', date);
                        }}
                        value={values.businessDate ? new Date(values.businessDate) : null}
                        slotProps={{
                          textField: {
                            name: 'businessDate',
                            size: 'small',
                            fullWidth: true,
                          },
                        }}
                        disabled={
                          isOtsInvoice &&
                          (values.paymentMethod === 'Credit Card Payment' ||
                            values.paymentMethod === 'Credit Card On File')
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <AmountInput
                        name="amountEach"
                        label="Amount"
                        value={values.amountEach}
                        onChange={val => {
                          setFieldValue('amountEach', val);
                        }}
                        onBlur={handleBlur}
                        error={!!touched.amountEach && !!errors?.amountEach}
                        helperText={
                          !!touched.amountEach && !!errors?.amountEach ? errors.amountEach : ''
                        }
                      />
                    </Grid>
                    {values.isOtsInvoice &&
                      values.paymentMethod === CREDIT_CARD_PAYMENT_METHOD &&
                      !isAdyen && (
                        <>
                          <Grid item xs={12} sm={6}>
                            <TextField label="First Name" name="firstName" required />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField label="Last Name" name="lastName" required />
                          </Grid>
                          <Grid item xs={12} sm={6} lg={4}>
                            <TextField label="Street" name="street" required />
                          </Grid>
                          <Grid item xs={12} sm={6} lg={4}>
                            <TextField label="City" name="city" required />
                          </Grid>
                          <Grid item xs={12} sm={6} lg={2}>
                            <TextField label="State" name="state" required />
                          </Grid>
                          <Grid item xs={12} sm={6} lg={2}>
                            <TextField label="Postal Code" name="zipCode" required />
                          </Grid>
                          <Grid item xs={12}>
                            <CreditCardInputs
                              numberValue={values.cardNumber}
                              cvvValue={values.cvv}
                              required
                              handleCreditCardNumberChange={val => setFieldValue('cardNumber', val)}
                              handleCvvChange={val => setFieldValue('cvv', val)}
                              handleBlur={handleBlur}
                              isInline
                            />
                          </Grid>
                        </>
                      )}
                    {values.showCCWarning &&
                      !!values.tranCodeId &&
                      (!isAdyen || !values.isOtsInvoice) && (
                        <Grid item xs={12}>
                          <Alert severity="warning">
                            <Typography>
                              This transaction type <strong>does not</strong> charge the Credit
                              Card.
                            </Typography>
                          </Alert>
                        </Grid>
                      )}
                  </Grid>
                  {isAdyen &&
                    values.paymentMethod === CREDIT_CARD_PAYMENT_METHOD &&
                    values.isOtsInvoice && (
                      <AdyenDropInWrapper
                        isValid={isValid}
                        handleClose={() => handleClose(resetForm)}
                        accountId={accountId}
                        repairId={repairId}
                        transactionId={transactionId}
                        amount={values.amountEach}
                        afterPaymentComplete={(adyenReference: string) => {
                          setSubmitting(true);
                          handlePostTransaction(values, resetForm, adyenReference, {
                            setSubmitting,
                          });
                        }}
                        handleReset={() => {
                          resetForm();
                        }}
                        isForSecurityDeposit={isForSecurityDeposit}
                        isForAdditionalSecurityDeposit={isForAdditionalSecurityDeposit}
                      />
                    )}
                  {/* This component is shared for the Customer > Transactions tab Post payment and OTS invoice modal */}
                  {((isAdyen && values.paymentMethod !== CREDIT_CARD_PAYMENT_METHOD) ||
                    !isAdyen ||
                    !values.isOtsInvoice) && (
                    <Stack
                      flexDirection={{ xs: 'column', sm: 'row' }}
                      gap={1}
                      justifyContent="flex-end"
                      sx={{ marginTop: '1rem' }}
                    >
                      <Button
                        color="inherit"
                        disabled={isSubmitting}
                        onClick={() => {
                          handleClose(resetForm);
                        }}
                        startIcon={<CancelIcon />}
                      >
                        Cancel
                      </Button>
                      <SaveButton disabled={isSubmitting || !isValid || !dirty} />
                    </Stack>
                  )}
                </Form>
              </Box>
            </Modal>
            <ConfirmCheckboxModal
              isOpen={values.isCCWarningModalOpen}
              handleClose={() => {
                setFieldValue('isCCWarningModalOpen', false);
                setSubmitting(false);
              }}
              handleSubmit={() => {
                setSubmitting(true);
                handlePostTransaction(values, resetForm, undefined, {
                  setSubmitting,
                });
              }}
              title="This will not charge the customer's credit card!"
              checkboxLabel="I understand this process does NOT charge the customer's card and should only be used to record a payment to apply to the customer's balance when the credit card has already been processed."
            />
          </>
        );
      }}
    </Formik>
  );
};
